import * as React from "react";
import AssetPage from "../../components/asset-page";

const XDCPage = () => (
  <AssetPage
    asset="XDC Network"
    ticker="XDC"
    description={[
      `The XDC Network, developed by XinFin, is an innovative hybrid blockchain platform designed to support global trade and finance. Merging the power of public and private blockchains, the XDC Network facilitates secure, efficient, and low-cost cross-border transactions. Its aim is to overcome the inefficiencies of traditional financial systems and bridge the gap between real-world assets and blockchain.`,
      `XinFin's XDC Network holds a promising place in the realm of enterprise blockchain solutions. Its hybrid architecture, combined with high throughput and energy-efficient consensus mechanism, makes it a formidable choice for businesses looking to leverage blockchain for trade finance, remittances, and more. As a gateway to decentralized and traditional finance, the XDC Network is poised to reshape the global digital economy landscape.`,
    ]}
    hideFeatureImage
    features={[
      {
        title: "Hybrid Blockchain Architecture",
        text: `The XDC Network's standout feature is its hybrid design, allowing for both private and public state transactions. This ensures a balance between transparency and privacy, catering to institutions that require confidential transactions, while still leveraging the benefits of a public chain.`,
      },
      {
        title: "Energy-Efficient Consensus",
        text: "Powered by the XinFin Delegated Proof of Stake (XDPoS) consensus mechanism, the XDC Network ensures rapid transaction finality with minimal energy consumption. This not only improves network scalability but also fosters sustainable blockchain operations.",
      },
      {
        title: "Interoperability & Flexibility",
        text: `XDC's open architecture supports smart contracts from Ethereum, making it easy for developers to deploy dApps. Moreover, its interoperability features ensure that XDC can seamlessly connect with other chains, paving the way for a more interconnected blockchain ecosystem.`,
      },
      {
        title: "Enterprise Ready",
        text: "The XDC Network is tailored for enterprise adoption with features like multi-signature wallets, KYC integration, and node-level permissions. This enterprise-centric approach ensures that businesses can confidently integrate blockchain into their existing workflows.",
      },
    ]}
  />
);

export default XDCPage;
